<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <!--<el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>-->
      <!-- 信息审核状态 -->
      <!--<el-form-item>
        <el-dropdown split-button type="primary" @command="getbyoauth">
          {{ $t('yh.xxshzt') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="5">{{
              $t('yh.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="0">{{ $t('yh.wrz') }}</el-dropdown-item>
            <el-dropdown-item command="1">{{
              $t('yh.sfrzz')
            }}</el-dropdown-item>
            <el-dropdown-item command="2">{{ $t('yh.sfrz') }}</el-dropdown-item>
            <el-dropdown-item command="3">{{
              $t('yh.gjrzz')
            }}</el-dropdown-item>
            <el-dropdown-item command="4">{{ $t('yh.gjrz') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>-->
      <!-- 身份标识 -->
      <!--<el-form-item>
        <el-dropdown split-button type="primary" @command="getbyisreal">
          {{ $t('yh.sfbs') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">{{
              $t('yh.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="1">{{ $t('yh.zsyh') }}</el-dropdown-item>
            <el-dropdown-item command="0">{{ $t('yh.xnyh') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>-->
    </el-form>
    <el-table v-loading="loading" :data="userlist" border style="width: 100%;">
      <el-table-column prop="id" label="#" width="80px"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <!-- <el-table-column :label="$t('yh.guojia')">//区号 -->
<!--        <template slot-scope="{ row }">
          {{ row.country + row.address }}
        </template> -->
<!--      </el-table-column> -->
      <el-table-column prop="phone" :label="$t('yh.sjh')"></el-table-column>
       <el-table-column prop="user" label="用户名"></el-table-column>
      <el-table-column
        prop="email"
        :label="$t('yh.youxiang')"
      ></el-table-column>
      <el-table-column prop="realname" :label="$t('yh.zsxm')"></el-table-column>
      <el-table-column prop="remarks" label="备注"></el-table-column>
      <el-table-column prop="is_real" :label="$t('yh.sfbs')">
        <template slot-scope="{ row }">
          <span v-if="row.is_real === '1'">{{ $t('yh.zsyh') }}</span>
          <span v-else>{{ $t('yh.xnyh') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('yh.fczh')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '1'">{{ $t('fou') }}</span>
          <span v-else>{{ $t('shi') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastlogintime"
        label="上线时间"
      ></el-table-column>
      <el-table-column
        prop="lxtime"
        label="离线时间"
      ></el-table-column>
      <el-table-column
        prop="oauth"
        :label="$t('yh.xxsh')"
        :formatter="oauthFormat"
      ></el-table-column>
      <el-table-column prop="risk" :label="$t('yh.yhfk')">
        <template slot-scope="scope">
          <span v-if="scope.row.risk === '-1'">{{ $t('yh.kuisun') }}</span>
          <span v-else-if="scope.row.risk === '0'">{{
            $t('yh.zhengchang')
          }}</span>
          <span v-else-if="scope.row.risk === '-2'">爆仓</span>
          <span v-else>{{ $t('yh.yingli') }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('yh.zccz')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('yh.bdqb')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-qianbao" @click="bindwallet(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('yh.zichan')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-gudingzichan" @click="assets(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('yh.djzc')"
            placement="top"
            :enterable="false"
          >
            <i
              class="iconfont icon-dongjiezongliang"
              @click="frozenassets(row.id)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-button
            size="small"
            v-if="row.oauth === '1'"
            @click="identity(row.id)"
            >{{ $t('yh.sfsh') }}</el-button
          >
          <el-button
            size="small"
            v-else-if="row.oauth === '3'"
            @click="advanced(row.id)"
            >{{ $t('yh.gjsh') }}</el-button
          >
          <span v-else></span>
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('yh.yhfk')"
            placement="top"
            :enterable="false"
          >
            <i
              class="iconfont icon-fengkongkehu"
              @click="riskcontrol(row.id)"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('yh.czmm')"
            placement="top"
            :enterable="false"
          >
            <i
              class="iconfont icon-zhongzhimima"
              @click="resetpassword(row.id)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <!-- <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination> -->

    <!-- 编辑用户弹窗区域 -->
    <edituser
      :visible="editdialogvisible"
      :uid="dialoguid"
      @close="editdialogvisible = false"
      @update="getlist"
    ></edituser>
    <!-- 用户风控弹窗区域 -->
    <riskcontrol
      :visible="riskcontroldialogvisible"
      :uid="dialoguid"
      @close="riskcontroldialogvisible = false"
      @update="getlist"
    ></riskcontrol>
    <!-- 绑定钱包弹窗区域 -->
    <bindwallet
      :visible="bindwalletdialogvisible"
      :uid="dialoguid"
      @close="bindwalletdialogvisible = false"
    ></bindwallet>
    <!-- 资产操作弹窗区域 -->
    <assets
      :visible="assetsdialogvisible"
      :uid="dialoguid"
      @close="assetsdialogvisible = false"
    ></assets>
    <!-- 冻结资产弹窗区域 -->
    <frozenassets
      :visible="frozenassetsdialogvisible"
      :uid="dialoguid"
      @close="frozenassetsdialogvisible = false"
    ></frozenassets>
    <!-- 身份审核 -->
    <identity
      :visible="identityvisible"
      :uid="dialoguid"
      @close="identityvisible = false"
      @update="getlist"
    ></identity>
    <!-- 高级审核 -->
    <advanced
      :visible="advancedvisible"
      :uid="dialoguid"
      @close="advancedvisible = false"
      @update="getlist"
    ></advanced>
  </div>
</template>

<script>
import assets from '@/components/user/assets.vue'
import frozenassets from '@/components/user/frozenassets.vue'
import riskcontrol from '@/components/user/riskcontrol.vue'
import bindwallet from '@/components/user/bindwallet.vue'
import edituser from '@/components/user/edituser.vue'
import identity from '@/components/user/identity.vue'
import advanced from '@/components/user/advanced.vue'
export default {
  components: {
    assets,
    frozenassets,
    riskcontrol,
    bindwallet,
    edituser,
    identity,
    advanced
  },
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        isreal: 2,
        oauth: 5,
        page: 1,
        group: 3,
        limit:999999
      },
      userlist: [],
      linshi:[],
      total: 0,
      loading: true,
      // 添加用户对话框
      dialogvisible: false,
      userform: {
        username: '',
        password: '',
        group: '',
        isreal: '1'
      },
      // 对话框打开的用户id
      dialoguid: '',
      // 编辑对话框
      editdialogvisible: false,
      // 用户风控对话框
      riskcontroldialogvisible: false,
      // 资产对话框
      assetsdialogvisible: false,
      // 冻结资产对话框
      frozenassetsdialogvisible: false,
      // 绑定钱包对话框
      bindwalletdialogvisible: false,
      // 身份审核
      identityvisible: false,
      // 高级审核
      advancedvisible: false,
      websocket:''
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
    this.initwebsocket()
  },
  methods: {
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl)
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onopen = this.websocketonopen
      this.websocket.onclose = this.websocketonclose
    },
    websocketonmessage(res){ 
      const data = JSON.parse(res.data)
      if(data.type=='user')
      {
        this.userlist = []
        if(data.list.length>0){
           data.list.forEach(item => {
             const zs = this.linshi.filter(p=>p.token==item.token);
             if(zs.length>0) this.userlist.push(zs[0])
           });
       }
      }
    },
    websocketonopen(){
      const msg = {type:'admin'}
      this.websocket.send(JSON.stringify(msg))
    },
    websocketonclose(){
      this.initwebsocket()
    },
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/userlist', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.linshi = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    adduser() {
      this.dialogvisible = true
    },
    // 关对话框
    handleClose() {
      this.dialogvisible = false
    },
    // 提交操作
    async submit() {
      const { data } = await this.$http.post(
        '/admin/user/adduser',
        this.userform
      )
      this.dialogvisible = false
      if (data.code === 200) {
        this.getlist(1)
      } else {
        this.$message.error(this.getlang(data.msg))
      }
      this.userform = { name: '', group: '', password: '', isreal: '1' }
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 信息审核状态
    oauthFormat(row) {
      const oauth = row.oauth
      let auth = ''
      switch (oauth) {
        case '1':
          auth = '身份认证中'
          break
        case '2':
          auth = '身份认证'
          break
        case '3':
          auth = '高级认证中'
          break
        case '4':
          auth = '高级认证'
          break
        default:
          auth = '未认证'
          break
      }
      return auth
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 筛选审核状态
    getbyoauth(command) {
      // console.log(command)
      this.queryinfo.oauth = command
      this.getlist()
    },
    // 筛选用户标识
    getbyisreal(command) {
      this.queryinfo.isreal = command
      this.getlist()
    },
    // 资产操作
    // 绑定钱包
    bindwallet(uid) {
      this.dialoguid = uid
      this.bindwalletdialogvisible = true
    },
    assets(uid) {
      this.dialoguid = uid
      this.assetsdialogvisible = true
    },
    // 冻结资产
    frozenassets(uid) {
      this.dialoguid = uid
      this.frozenassetsdialogvisible = true
    },
    // 编辑
    edit(uid) {
      this.dialoguid = uid
      this.editdialogvisible = true
    },
    // 用户风控
    riskcontrol(uid) {
      this.dialoguid = uid
      this.riskcontroldialogvisible = true
    },
    // 重置密码
    resetpassword(uid) {
      this.dialoguid = uid
      this.$confirm('确认重置该用户密码?', this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      })
        .then(async () => {
          const { data } = await this.$http.post('/admin/user/resetpassword', {
            id: uid
          })
          if (data) {
            if (data.code === 200) {
              this.$alert('重置后密码为111111')
            } else {
              this.$message.error(data.msg)
            }
          }
        })
        .catch(() => {
          // this.$message.info('已取消')
        })
    },
    // 身份审核
    identity(uid) {
      this.dialoguid = uid
      this.identityvisible = true
    },
    // 高级审核
    advanced(uid) {
      this.dialoguid = uid
      this.advancedvisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
